import React from 'react';
import nav from '../images/logo.png';
import { BrowserRouter,NavLink,Routes,Route } from 'react-router-dom';
import Contact from './Contact';
import Career from './Career';
import Home from "./Home";
import Footer from './Footer';
import Login from '../Login';
import Signup from '../Signup';
const {useState} = React;
const Navbar = () => {
    const [active, setActive] = useState("1");
 
    const handleClick = (event) => {
      setActive(event.target.id);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  
    return (
        <>
        <BrowserRouter>

        <section className="nav-sec navbar1 w-100">
 
            <div className="container">
                <nav className="navbar navbar-expand-md navbar-light ">
                    <a
                     className="navbar-brand" href="/"><img id="imgLocation" src={nav} alt="logo"/></a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul className="navbar-nav nav-items ml-auto">
                            <li className="nav-item">
                            <NavLink  to="/#demos"  data-scroll  key={1}
                className={active === "1" ? "active2 nav-link1" : "nav-link1"}
                 id={"1"}
                 onClick={handleClick}>Home</NavLink>
                            </li>
                            <li className="nav-item">
                            <a  className={active === "2" ? "active2 nav-link1" : "nav-link1"}
                 id={"2"}
                 key={2}
                 onClick={handleClick} data-scroll href="/#demo1" >About</a>
                            </li>
                            <li className="nav-item">
                                <a className={active === "3" ? "active2 nav-link1" : "nav-link1"}
                 id={"3"}
                 key={3}
                 onClick={handleClick} data-scroll href="/#demo2">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className={active === "4" ? "active2 nav-link1" : "nav-link1"}
                 id={"4"}
                 key={4}
                 onClick={handleClick} data-scroll href="/#demo3">Prorfolio</a>
                            </li>
                            <li className="nav-item">
                                <a className={active === "5" ? "active2 nav-link1" : "nav-link1"}
                 id={"5"}
                 key={5}
                 onClick={handleClick} data-scroll href="/#demo4">Team</a>
                            </li>
                            <li className="nav-item">
                                 <NavLink className={active === "6" ? "active2 nav-link1" : "nav-link1"}
                 id={"6"}
                 key={6}
                 onClick={handleClick} to="career">Career</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={active === "7" ? "active2 nav-link1" : "nav-link1"}
                 id={"7"}
                 key={7}
                 onClick={handleClick} to="contact">Contact</NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink className={active === "8" ? " login-btn login-btn1" : "login-btn"}
                 id={"8"}
                 key={8}
                 onClick={handleClick} to="login">Login</NavLink>
                            </li>
                        </ul>
                    </div> 
                </nav>
            </div>
           
        </section>

        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path="/career" element={<Career/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/login/sigup" element={<Signup/>}/>
        </Routes>

        </BrowserRouter>
        <Footer/>
        </>
    );
}
export default Navbar;