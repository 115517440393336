import React from "react";
const Footer = () => {
let date =new Date();
let year=date.getFullYear();
    return (<>
        <section className="footer-sec">
        <div className="container">
            <div className="row py-5">
                <div className="col-md-4 col-12">
                    <div className="footer-text footer1 ">
                    <a class="navbar-brand" href="google.html"><img id="imgLocation" src="https://www.linkpicture.com/q/logo_746.png" style={{height:'45px', width:'100px'
}} alt="logo"/></a>
                    <ul> 
                    <li>Info@bzbeetech.com</li>
                    <li>+92306 220 20 59</li>
                    </ul>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div className=" footer-text footer2">
                    <h3>Social Media</h3>
                    <ul> 
                    <li><a href="https://www.facebook.com/BusyBeeTechnologies/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                    {/* <li>  <i class="fa fa-google-plus" aria-hidden="true"></i> </li> */}
                    <li><a href="https://www.linkedin.com/company/18858818/"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>    <p className=" text-dark ">
                        
                        </p>
                    </div>
                </div>
                <div className="col-md-4 col-12">
                    <div className=" footer-text footer3">
                    <h3>Address</h3>
                    <ul> 
                    <li>Lahore, Punjab</li>
                    <li>Pakistan</li>
                    </ul>
                    </div>
                </div>
            </div>
        </div>
        </section>
        <section>
<div className="bottom-footer text-center p-">
    <p className="p-0 m-0">© {year} BusyBeeTech. All Rights Reserved.</p>
</div>
        </section>  
        </>
    )
}
export default Footer;