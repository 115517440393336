import React from 'react'
import { BrowserRouter,NavLink,Routes,Route } from 'react-router-dom';
const Signup = () => {
  return (
    <>
    <section className='login-sec py-5'>
    <div class="containerlog">
    <h2 class="login-title">Sign up</h2>

    <form class="login-form">

    <div>
        <label for="name">Name </label>
        <input
               id="name"
               type="text"
               placeholder="Eren Buruk"
               name="name"
               required
               />
      </div>
      <div>
        <label for="email">Email </label>
        <input
               id="email"
               type="email"
               placeholder="me@example.com"
               name="email"
               required
               />
      </div>

      <div>
        <label for="password">Password </label>
        <input
               id="password"
               type="password"
               placeholder="password"
               name="password"
               required
               />
      </div>
      
      <div>
        <label for="password">Confirm Password</label>
        <input
               id="password"
               type="password"
               placeholder="confirm password"
               name="password"
               required
               />
      </div>

      <button class="btnlog " type="submit" value="Log in">
        Sign Up
      </button>
    </form>
    <div className='bot_link pt-3'>
   <p className='m-0'> Already have an account? <NavLink className="auth" to="../login">Login</NavLink></p>
    </div>
</div>
    </section>

    </>
  )
}

export default Signup