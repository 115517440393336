import card1 from '../images/01.png';
import card2 from '../images/02.png';
// import card3 from '../images/03.png';
// import card4 from '../images/04.png';
// import card5 from '../images/05.png';
import card6 from '../images/06.png';
const cardData = [
    {
        id: '1',
        name: 'Web Designer',
        text: 'We provide UI/UX design services to gain eye-catching aesthetics for your software. Let’s design an interface that boosts customer retention.',

        imgsrc:card1
    }, {
        id: '2',
        name: 'Web Developer',
        text: 'We create brilliant websites that are fully responsive, functional, secure, and scalable enough to help you separate yourself from the competition.',

        imgsrc:card2
    }, {
        id: '3',
        name: 'Project Management',
        text: 'We provide services as project manager for your upcoming projects, ensuring smooth workflow between developers, designers, devops, and other stakeholders.Ensuring smooth transition of project through software development life cycle. ',
        imgsrc:card6
    }
]
export default cardData;