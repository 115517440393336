import React from "react";
import vector4 from '../images/vector-4.png';
const Career = () => {
    return (
<>
<section className="page-banner">
        <div className="bg-banner-gradient">
            <div className="layer-overlay"></div>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="content-area">
                            <span>CAREER</span>
                            <h1>
                            Create clever things<br/>
                            with us! 
                            
                            </h1>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={vector4} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="post-job">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="pj">
                        <h6 class="text-gradient">work in best environment</h6>
                        <h2>the home your <span class="text-gradient">dream job</span></h2>
                        <p>We all have dreams that we want to fulfill at one point in our lives. However, this is not easily achieved, <br/> but we always have to work hard for it.Having a dream job would definitely help you to be on track to achieve your goal in life and aim to be there.</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 filter-card">
                    <div class="card">
                        <div class="card-body">
                            <form action="#">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <input type="text" name="" id="" class="form-control" placeholder="Search Kyewords" required="" />
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <select class="form-control" name="" id="" required="">
                                                <option value="">Browse Job Category</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <button type="submit" class="btn btn-warning btn-block btn-shap-2 m-0">Find Job</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="sort-bar d-flex justify-content-between flex-column flex-md-row">
                        <span>There are 15 job post found</span>
                        <div>
                            <span>sort by: </span>
                            <select name="" id="" className="select_field">
                                <option value="">newest to oldest post</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
            </div>
        </div>
    </section>
    
</>
    );
}
export default Career;