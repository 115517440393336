import React from 'react'
import { BrowserRouter,NavLink,Routes,Route } from 'react-router-dom';
const Login = () => {
  return (
    <>
    <section className='login-sec py-5'>
    <div class="containerlog">
    <h2 class="login-title">Login</h2>

    <form class="login-form">


      <div>
        <label for="email">Email </label>
        <input
               id="email"
               type="email"
               placeholder="me@example.com"
               name="email"
               required
               />
      </div>

      <div>
        <label for="password">Password </label>
        <input
               id="password"
               type="password"
               placeholder="password"
               name="password"
               required
               />
      </div>

      <button class="btnlog " type="submit" value="Log in">
        Login
      </button>
    </form>
    <div className='bot_link pt-3'>
   <p className='m-0'> Don't have an account?<NavLink  className="auth" to="sigup"> Sign up </NavLink></p>
    </div>
</div>
    </section>

    </>
  )
}

export default Login