import React from "react";
import vector from '../images/vector-5.png';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
const Contact = () => {
    return (
<>
<section className="page-banner">
        <div className="bg-banner-gradient">
            <div className="layer-overlay"></div>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="content-area">
                            <span>portfolio</span>
                            <h1>
                                contact for Amazing <br/>
                                best Services <br/>
                                From us.
                            </h1>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <img src={vector} alt="" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="contact-section">
        <div className="container">
            <div className="row">
                <div className="col-md-4">
                    <img src="http://127.0.0.1:8000/main-assets/img/vectors/vector-7.png" className="img-fluid" alt="" />
                </div>
                <div className="col-md-8 form-col">
                    <div className="row">
                        <div className="col-md-12">
                                                    </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <p className="work-text text-gradient">contact us now</p>
                            <h2 className="work-heading">
                                your opinion <span className="text-gradient">matters</span>
                            </h2>
                            <p className="work-desc">
                                {/* Lorem ipsum dolor sit amet consectetur adipiscin elit sed
                                tempor incididunt ut labore et dolore magnan */}
                            </p>
                        </div>
                    </div>
                    <form action="#">
                        <input type="hidden" name="_token" value="vzhsFNwDQ9wWKamDU4iCt0gnAFgkVvWtJlBol7ZP" />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" name="full_name" id="" className="form-control" placeholder="Full Name" required="" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="email" name="email" id="" className="form-control" placeholder="Email" required="" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input type="text" name="subject" id="" className="form-control" placeholder="Subject" required="" />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <textarea name="description" id="" cols="30" rows="10" className="form-control"></textarea>
                            </div>
                            <div className="col-md-12 mt-5 text-end">
                            <button class="contact-btn">Send Now <span><ChevronRightIcon/></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</>
    );
}
export default Contact;