import React from "react";
import AboutSection from './AboutSection';
import PortfolioSection from "../PortfolioSection";
import GallarySection from "../GallarySection";
import SliderSection from "../SliderSection";
import CardSection from './CardSection';
import Header from './Header'
const Home = () => {
    return (
        <>
        < Header />
        <AboutSection/> 
        < CardSection />
        {/* <Roundslider/> */}
        <PortfolioSection/>
        <GallarySection/>
        <SliderSection/>
        </>

    );
}
export default Home;