import React from "react";
// import about from '../images/about-left.png';
import tick from "../images/tick.png"
const AboutSection = () => {
    return (
        <section className="about-sec" id="demo1">
        <div class="container " >
            <div class="row">
                <div class="col-lg-6 col-md-12">
                {/* <img className="header-right" src={about} alt="header-right"/> */}
                </div>
                <div class="col-lg-6 col-md-12">
              <div className="about-right">
<h1>About us</h1>
<h3>We provide Creative solutions<br/> 
to improve your business!
</h3>
<p>
Busy Bee Technologies is a software company that specializes in software development. Since 2010, the company has offered a wide range of high quality services in the development, delivery, and maintenance of software in worldwide. Our key market advantage is the ability to leverage a wealth of experience in this sector, a network of local and international clients, and a very competitive pricing strategy to deliver quality work.
</p>

<ul>
<li><img src={tick} alt=""/> Website Design and Development</li>
  <li><img src={tick} alt=""/> Software Architecture Design </li>
  <li><img src={tick} alt=""/> Software Quality Assurance</li>
  <li><img src={tick} alt=""/> Search Engine optimization</li>
  
</ul> 
              </div>
                </div>
            </div>
            {/* modal */}
    
        </div>
        </section>
    );
}
export default AboutSection;