import React from "react";
import portfolio from '../src/images/portfolio-right1.jpg';
const PortfolioSection =()=>{
    return(
    <>
<section className="portfolio-sec" id="demo3">


<div className="container">

<div className="row">
<div className="col-md-12 col-lg-6 col-12">
<div className="jumbotron pt-2 mb-0 text-left bg-transparent ">
                <div className="container top-card-text">
                    <h5><span>Our Best Work Portfolio</span></h5>
                    <h3>A place where <span>ideas grow</span></h3>
                    <p>The truly great ideas, are the product of processes. They are the product of what a person sees and gets in touch with every day that combine to influence a thought. A random thought turns into an idea, then the idea is worked on.</p>

<ul><li className="li-3"> <span className="iconhead">Software Innovation</span></li>
  <p className="pl-5">Innovation in software development is instrumental for us to set our products and services apart from our competitors. Software innovation drives the change that later gets adapted as industry trends.  </p>
  <li> <span className="iconhead">Customer loyalty</span> </li>
  <p className="pl-5">Our investment in clients means they don’t just receive a team of highly experienced developers, but also the support and insights of accomplished engineers and business analysts.</p>
  <li className="li-2"> <span className="iconhead">Product Quality</span></li>
  <p className="pl-5">Well, we do not begin the development of the scope of the project until it is clear enough. To test your product early, we like to design wireframes or mockups. From the very beginning, we ensure quality control by working with the development team to verify that the standards are being met. The key to success is communication, so we arrange daily or weekly scrum meetings and stay accessible on product development tools at all times. </p>
  
</ul> 
                </div>
            </div>
</div>
<div className="col-md-12 col-lg-6 col-12">
      <img className="header-right pt-5"  src={portfolio} alt="portfolio"/> 
</div>
</div>
</div>


</section>

    </>
)




}
export default PortfolioSection;